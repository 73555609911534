// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-azar-connect-1-tsx": () => import("./../../../src/pages/azar-connect-1.tsx" /* webpackChunkName: "component---src-pages-azar-connect-1-tsx" */),
  "component---src-pages-azar-connect-2-tsx": () => import("./../../../src/pages/azar-connect-2.tsx" /* webpackChunkName: "component---src-pages-azar-connect-2-tsx" */),
  "component---src-pages-faruk-k-tsx": () => import("./../../../src/pages/faruk-k.tsx" /* webpackChunkName: "component---src-pages-faruk-k-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-india-h-tsx": () => import("./../../../src/pages/india-h.tsx" /* webpackChunkName: "component---src-pages-india-h-tsx" */),
  "component---src-pages-kr-m-bda-int-and-aw-201211-tsx": () => import("./../../../src/pages/KR_M_BDA_INT_AND_AW_201211.tsx" /* webpackChunkName: "component---src-pages-kr-m-bda-int-and-aw-201211-tsx" */),
  "component---src-pages-mena-tsx": () => import("./../../../src/pages/mena/[...].tsx" /* webpackChunkName: "component---src-pages-mena-tsx" */),
  "component---src-pages-recruitment-creator-index-tsx": () => import("./../../../src/pages/recruitment/creator/index.tsx" /* webpackChunkName: "component---src-pages-recruitment-creator-index-tsx" */),
  "component---src-pages-vvip-index-tsx": () => import("./../../../src/pages/vvip/index.tsx" /* webpackChunkName: "component---src-pages-vvip-index-tsx" */),
  "component---src-pages-vvip-welcome-tsx": () => import("./../../../src/pages/vvip/welcome.tsx" /* webpackChunkName: "component---src-pages-vvip-welcome-tsx" */)
}

